<template>
  <v-container fluid class="articles-container pa-4 pa-sm-6 pa-md-8">
    <v-row>
      <v-col cols="12" md="4" lg="3">
        <v-card class="mb-6" elevation="2">
          <v-card-text>
            <v-text-field
              v-model="form.titleKeyword"
              label="按关键字查询"
              @keyup.enter="search"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-btn color="primary" @click="search" class="mt-3" block>查询</v-btn>
          </v-card-text>
        </v-card>

        <v-card class="mb-6" elevation="2">
          <v-card-title class="subtitle-1 font-weight-bold">热门作者</v-card-title>
          <v-card-text>
            <!-- 在移动端显示下拉框 -->
            <v-select
              v-if="$vuetify.breakpoint.smAndDown"
              :items="authors"
              label="选择作者"
              @change="author"
              outlined
              dense
            ></v-select>
            <!-- 在桌面端显示网格布局 -->
            <v-row v-else class="authors-grid">
              <v-col v-for="a in authors" :key="a" cols="6">
                <v-btn
                  @click="author(a)"
                  text
                  block
                  class="author-btn text-none"
                  :ripple="{ class: 'primary--text' }"
                >
                  {{ a }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-6" elevation="2">
          <v-card-title class="subtitle-1 font-weight-bold">排序</v-card-title>
          <v-card-text>
            <v-select
              v-model="form.sort"
              :items="sortOptions"
              label="选择排序方式"
              @change="sort"
              outlined
              dense
            ></v-select>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="8" lg="9">
        <v-card v-for="e in list" :key="e.id" class="mb-4" elevation="2">
          <v-card-text>
            <div class="d-flex align-center">
              <v-btn
                :class="['love mr-4', e.love > 0 ? 'loved' : 'unloved']"
                @click="love(e)"
                icon
              >
                <v-icon>mdi-thumb-up</v-icon>
              </v-btn>
              <div class="love-count mr-4">{{ e.love }}</div>
              <div class="flex-grow-1">
                <div class="subtitle-2 mb-1">{{ e.author }} @{{ e.site }}</div>
                <a :href="e.link" target="_blank" class="title">{{ e.title }}</a>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

let baseUrl = "https://qidafang.com"

export default {
  name: 'articles',
  data() {
    return {
      form: {
        titleKeyword: '',
        type: 'default',
        keyword: '',
        sort: 'love'
      },
      sortOptions: [
        { text: '最受欢迎', value: 'love' },
        { text: '最新收录', value: 'id' }
      ],
      list: [],
      authors: '煮酒正熟,辽东管宁,李天飞,与觚斋,娃娃鱼,赵学浩,杨文理,李靖岩,无为楼主,唯笑惟笑,白马将军上官迪,FFF团长,曹豫,释延德,杨沐梓,江宇舟,吕布姜维,卫迟,刀与笔,应弦吹笛,岳麓韶阳,哈士奇思维模拟器,安田秀彦,捣浆糊,欧小生,文若,静静的流浪猫,景谦,关2哥,芦苇,水镜,秦时明月,糗百小神仙,张小生,白菜,淮右咸鱼,夏至,刘寄奴,祁达方'
        .split(',')
    }
  },
  methods: {
    reset() {
      this.form.type = 'default'
      this.form.keyword = ''
      this.form.sort = 'love'
      this.form.titleKeyword = ''
      this.doSearch()
    },
    search() {
      this.form.type = 'title'
      this.form.keyword = this.form.titleKeyword
      this.form.sort = 'love'
      this.doSearch()
    },
    author(a) {
      const selectedAuthor = a && a.target ? a.target.value : a;
      this.form.type = 'author';
      this.form.keyword = selectedAuthor;
      this.form.sort = 'love';
      this.form.titleKeyword = '';
      this.doSearch();
    },
    sort() {
      this.doSearch();
    },
    love(e) {
      axios.post(baseUrl + '/api/story/friendArticles/love/' + e.id)
        .then(response => {
          if (response.data.code === 'success') {
            e.love += 1;
            alert(response.data.data);
          } else {
            alert(response.data.data);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    doSearch() {
      if (this.form) {
        // 将查询表单记录到缓存
        localStorage.setItem('search_form', JSON.stringify(this.form))
      }

      var that = this

      let url = baseUrl + '/api/story/friendArticles/search?';
      url += 'titleKeyword=' + encodeURIComponent(this.form.titleKeyword);
      url += '&type=' + encodeURIComponent(this.form.type);
      url += '&keyword=' + encodeURIComponent(this.form.keyword);
      url += '&sort=' + encodeURIComponent(this.form.sort);

      axios.get(url)
        .then(response => {
          this.list = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  computed: {
  },
  created() {
    this.doSearch()
  }
}
</script>

<style scoped>
.articles-container {
  background-color: #f5f5f5;
  min-height: calc(100vh - 100px); /* 调整为顶部导航栏和页脚的总高度 */
}

.authors-grid {
  margin-top: -12px;
  margin-bottom: -12px;
}

.author-btn {
  height: 40px;
  font-size: 14px;
  text-transform: none;
  transition: all 0.3s ease;
}

.author-btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
  transform: translateY(-2px);
}

.love {
  transition: all 0.3s ease;
}

.love.loved {
  color: #e57373;
}

.love-count {
  font-size: 16px;
  font-weight: bold;
  color: #757575;
}

a {
  text-decoration: none;
  color: #1e88e5;
  transition: color 0.3s ease;
}

a:hover {
  color: #1565c0;
}
</style>
