import Vue from 'vue'
import Router from 'vue-router'
import Articles from './views/Articles.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      component: Articles,
    },
    {
      path: '/me',
      name: 'me',
      component: () => import('./views/Me.vue'),
    },
    {
      path: '/register/:code',
      name: 'register',
      component: () => import('./views/Register.vue'),
      props: true
    },
    {
      path: '/read/:type/:chapterId',
      name: '阅读',
      component: () => import('./views/Read.vue'),
      props: true
    },
    {
      path: '/articles',
      name: 'articles',
      component: () => import('./views/Articles.vue')
    },
    {
      path: '/read/:id',
      name: 'read-article',
      component: () => import('./views/Random.vue')
    },
    {
      path: '/random',
      name: 'random',
      component: () => import('./views/Random.vue')
    },
    {
      path: '/force',
      name: 'force',
      component: () => import('./views/Force.vue')
    },
    {
      path: '/vote/:topic',
      name: 'vote',
      component: () => import('./views/Vote.vue')
    },
    {
      path: '/result',
      name: 'result',
      component: () => import('./views/Result.vue')
    },
    {
      path: '/pk/:topic',
      name: 'pk',
      component: () => import('./views/PK.vue')
    },
    {
      path: '/doc/:name',
      name: 'doc',
      component: () => import('./views/Doc.vue'),
      props: true
    },
    {
      path: '/forum',
      name: 'forum',
      component: () => import('./views/ForumList.vue'),
      props: true
    },
    {
      path: '/post/:id',
      name: 'post',
      component: () => import('./views/ForumPost.vue'),
      props: true
    },
    {
      path: '/answer/:bankId',
      name: '答题',
      component: () => import('./views/Answer.vue'),
      props: true
    },
    {
      path: '/draw',
      name: '你画我猜',
      component: () => import('./views/Draw.vue')
    },
    {
      path: '/talk',
      name: 'AI问答',
      component: () => import('./views/Talk.vue')
    },
    {
      path: '/check-for-wj-zly',
      name: '文本检测',
      component: () => import('./views/tools/Check.vue')
    },
    // 维基页面
    {
      path: '/record/:id',
      name: '维基条目',
      component: () => import('./views/Record.vue'),
      props: true
    },    
    {
      path: '/edit-record/:id?',
      name: '编辑条目',
      component: () => import('./views/RecordEditor.vue'),
      props: true
    },  
    {
      path: '/search',
      name: '搜索条目',
      component: () => import('./views/RecordSearch.vue'),
      props: (route) => ({ query: route.query.query })
    },
    {
      path: '/edit-record-category',
      name: '管理分类',
      component: () => import('./views/RecordCategoryEditor.vue'),
      props: true
    },
  ]
})
