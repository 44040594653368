<template>
  <v-app>
    <v-app-bar app color="#1e496e" dark elevate-on-scroll>
      <v-app-bar-nav-icon @click="drawer = !drawer" class="hidden-md-and-up"></v-app-bar-nav-icon>
      <v-toolbar-title class="d-flex align-center">
        <router-link to="/" class="d-flex align-center text-decoration-none">
          <v-img
            alt="演义细读网"
            class="shrink mr-2"
            contain
            src="https://cdn-427studio.oss-cn-qingdao.aliyuncs.com/sanguo_header/11/%E5%88%98%E5%A4%87.jpg"
            transition="scale-transition"
            width="40"
          />
          <span class="text-h6 font-weight-bold white--text">演义细读网</span>
        </router-link>
      </v-toolbar-title>

      <div class="d-none d-md-flex align-center">
        <div v-for="menu in filteredMenus" :key="menu.title">
          <v-btn
            v-if="menu.href"
            :to="menu.external ? undefined : menu.href"
            :href="menu.external ? getExternalUrl(menu.href) : undefined"
            @click.prevent="menu.external ? openExternalLink(menu.href) : null"
            text
            class="mx-1 nav-btn"
          >
            {{ menu.title }}
          </v-btn>
          <v-menu
            v-else-if="menu.items && menu.items.length > 0"
            offset-y
            open-on-hover
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
                class="mx-1 nav-btn"
              >
                {{ menu.title }}
                <v-icon small class="ml-1">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="item in menu.items"
                :key="item.title"
                :to="item.external ? undefined : item.href"
                :href="item.external ? getExternalUrl(item.href) : undefined"
                @click.prevent="item.external ? openExternalLink(item.href) : null"
                link
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <v-spacer></v-spacer>

      <v-btn v-if="!$store.getters.isLoggedIn" @click="loginDialog = true" text class="login-btn">
        登录
      </v-btn>
      <v-btn v-else to="/me" color="#EE964B" class="white--text user-btn">
        {{ $store.state.username }}
      </v-btn>

      <v-btn icon v-if="$store.getters.isLoggedIn" class="ml-2" @click="markNotificationsAsRead">
        <v-badge color="red" :content="unreadNotificationsCount" :value="unreadNotificationsCount">
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app temporary class="hidden-md-and-up mobile-drawer">
      <v-list nav dense>
        <v-list-item-group>
          <div v-for="(menu, index) in filteredMenus" :key="index">
            <v-list-item 
              v-if="menu.href" 
              :to="menu.external ? undefined : menu.href"
              :href="menu.external ? getExternalUrl(menu.href) : undefined"
              @click.prevent="menu.external ? openExternalLink(menu.href) : null"
              link
            >
              <v-list-item-icon>
                <v-icon>{{ menu.icon || 'mdi-chevron-right' }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ menu.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group v-else-if="menu.items && menu.items.length > 0" no-action>
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>{{ menu.icon || 'mdi-menu-down' }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ menu.title }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item 
                v-for="(item, itemIndex) in menu.items" 
                :key="itemIndex" 
                :to="item.external ? undefined : item.href"
                :href="item.external ? getExternalUrl(item.href) : undefined"
                @click.prevent="item.external ? openExternalLink(item.href) : null"
                link
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer app color="#1e496e" dark class="py-2">
      <v-container class="pa-0">
        <v-row justify="center" align="center" no-gutters>
          <v-col cols="12" class="text-center body-2">
            &copy; 2022 - {{ new Date().getFullYear() }} 演义细读微信群. All rights reserved.
          </v-col>
        </v-row>
      </v-container>
    </v-footer>

    <!-- 在 v-app 标签的末尾，添加登录对话框 -->
    <v-dialog v-model="loginDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline">登录</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="login">
            <v-text-field
              v-model="username"
              label="用户名"
              required
            ></v-text-field>
            <v-text-field
              v-model="password"
              label="密码"
              type="password"
              required
            ></v-text-field>
            <v-btn color="primary" type="submit" block>登录</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
import service from '@/utils/request';
import stompClient from '@/utils/websocket.js';
import _ from 'lodash';

export default {
  name: 'App',
  data() {
    return {
      menus: [
        {
          title: '人物百科',
          href: '/search',
          requireLogin: true,
        },
        {
          title: '阅读原著',
          items: [
            { href: '/read/嘉靖壬午本/1', title: '在线阅读' },
            { href: 'https://qidafang.com/autologin', title: '高级阅读', requireLogin: true, external: true },
            { href: '/doc/links', title: '参考资料' },
          ],
        },
        {
          title: '近期活动',
          items: [
            { href: '/vote/water_force_heros', title: '梁山武评', requireLogin: true },
            { href: '/vote/water_force', title: '水浒武评', requireLogin: true },
            { href: '/vote/intelligence', title: '演义智评', requireLogin: true },
            { href: '/vote/warrior_intelligence', title: '武将智评', requireLogin: true },
            { href: '/pk/intelligence', title: '智力PK', requireLogin: true },
            { href: '/result', title: '活动结果' },
          ],
        },
        {
          title: '讨论区',
          requireLogin: true,
          items: [
            { href: 'https://bbs.sanguo.wiki/', title: '内部论坛', requireLogin: true, external: true },
            { href: '/random', title: '翻翻好帖', requireLogin: true },
            { href: '/talk', title: 'AI问答', requireLogin: true },
          ],
        },
        {
          title: '小游戏',
          requireLogin: true,
          items: [
            { href: 'https://battle.sanguo.wiki', title: '武将对战', requireLogin: true, external: true },
            { href: '/answer/1', title: '知识答题' },
            { href: '/draw', title: '你画我猜', requireLogin: true },
          ],
        },
      ],
      drawer: false, // 控制侧边菜单的显示与隐藏
      loginDialog: false, // 控制登录对话框的显示与隐藏
      username: '',
      password: '',
      snackbar: false, // 控制消息提示的显示与隐藏
      snackbarText: '', // 消息提示的文本内容
      snackbarColor: 'success', // 消息提示的颜色
      notifications: [], // 存储通知消息
      dialog: false, // 控制系统消息对话框的显示与隐藏
      isMobile: false, // 判断是否为移动设备
    };
  },
  computed: {
    // 过滤后的菜单项，仅显示满足条件的菜单
    filteredMenus() {
      const isLoggedIn = this.$store.getters.isLoggedIn;
      return this.menus.map(menu => {
        // 处理一级菜单
        if (menu.requireLogin && !isLoggedIn) {
          return null; // 如果需要登录且未登录，返回 null
        }

        // 处理子菜单
        if (menu.items) {
          menu = { ...menu }; // 创建菜单项的浅拷贝
          menu.items = menu.items.filter(item => {
            // 如果子菜单项没有 requireLogin 属性或已登录，则显示
            return !item.requireLogin || isLoggedIn;
          });
        }

        return menu;
      }).filter(Boolean); // 过滤掉 null 值
    },
    // 判断用户是否已登录
    isLoggedIn() {
      return !!localStorage.getItem('token');
    },
    // 获取高级阅读的 URL
    readUrl() {
      return 'https://qidafang.com/autologin?token=' + localStorage.getItem('token');
    },
    // 获取未读通知的数量
    unreadNotificationsCount() {
      return this.notifications.filter(notification => !notification.isRead).length;
    },
  },
  provide() {
    return {
      stompClient: stompClient,
      showMessage: this.showMessage,
    };
  },
  methods: {
    // 登录方法
    login() {
      this.$store.dispatch('login', {
        username: this.username,
        password: this.password,
      }).then(() => {
        console.log('登录成功，关闭对话框');
        this.loginDialog = false;
        this.username = '';
        this.password = '';
        this.showMessage('登录成功', 'success');
      }).catch(error => {
        console.error('登录失败:', error);
        let errorMessage = '请检查用户名和密码';
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        this.showMessage('登录失败: ' + errorMessage, 'error');
      });
    },
    // 显示消息提示
    showMessage(message, color = 'success') {
      this.snackbarText = message;
      this.snackbarColor = color;
      this.snackbar = true;
    },
    // 获取通知消息
    getNotifications() {
      service.get('/wikiapi/notifications')
        .then(response => {
          this.notifications = response.data;
        })
        .catch(error => {
          console.error(error);
          this.showMessage('获取通知失败', 'error');
        });

      // 订阅消息
      stompClient.connect({}, (frame) => {
        stompClient.subscribe('/user/queue/notifications', (notification) => {
          const newNotification = JSON.parse(notification.body);
          this.notifications.unshift(newNotification);
        });
      });
    },
    // 标记通知为已读
    markNotificationsAsRead() {
      this.dialog = true;
      this.notifications.forEach(notification => {
        if (!notification.isRead) {
          service.put(`/wikiapi/notifications/${notification.id}`)
            .then(() => {
              notification.isRead = true;
            })
            .catch(error => {
              console.error(error);
              this.showMessage('标记通知为已读失败', 'error');
            });
        }
      });
    },
    // 检查是否为移动设备
    checkIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    // 防抖处理窗口大小变化事件
    debounceCheckIsMobile: _.debounce(function() {
      this.checkIsMobile();
    }, 200),
    getExternalUrl(baseUrl) {
      const token = localStorage.getItem('token');
      if (baseUrl.includes('qidafang.com')) {
        return `${baseUrl}?token=${token}`;
      } else if (baseUrl.includes('bbs.sanguo.wiki')) {
        return `${baseUrl}?auth_token=${token}`;
      } else if (baseUrl.includes('battle.sanguo.wiki')) {
        return `${baseUrl}?token=${token}`;
      }
      // 对于其他外部链接，返回原始 URL
      return baseUrl;
    },
    openExternalLink(baseUrl, event) {
      if (event) {
        event.preventDefault();
      }
      const url = this.getExternalUrl(baseUrl);
      window.open(url, '_blank');
    },
  },
  watch: {
    // 监听登录状态变化
    '$store.getters.isLoggedIn': {
      handler: function (isLoggedIn) {
        if (isLoggedIn) {
          this.getNotifications();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // 初始检查设备类型
    this.checkIsMobile();
    // 添加窗口大小变化事件监听器
    window.addEventListener('resize', this.debounceCheckIsMobile);

    // 如果用户已登录，获取通知
    if (this.$store.getters.isLoggedIn) {
      this.getNotifications();
    }
  },
  beforeDestroy() {
    // 移除窗口大小变化事件监听器
    window.removeEventListener('resize', this.debounceCheckIsMobile);
  },
};
</script>

<style>
.v-application {
  font-family: 'Roboto', 'Noto Sans SC', sans-serif !important;
}

.v-app-bar {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.nav-btn {
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
  text-transform: none !important;
  transition: background-color 0.3s ease !important;
}

.nav-btn:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.v-menu__content {
  border-radius: 0 !important; /* 移除圆角 */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
  overflow: hidden !important;
}

.v-list {
  padding: 0 !important; /* 移除内边距 */
}

.v-list-item {
  border-radius: 0 !important; /* 确保列表项也没有圆角 */
  min-height: 36px !important;
  padding: 0 16px !important;
  transition: background-color 0.2s ease, color 0.2s ease !important;
}

.v-list-item__title {
  font-size: 14px !important;
  color: #333 !important;
  font-weight: 400 !important;
}

.v-list-item:hover {
  background-color: #f0f0f0 !important;
}

.v-list-item:hover .v-list-item__title {
  color: #1e496e !important;
}

/* 为子菜单添加分隔线 */
.v-list-item:not(:last-child) {
  border-bottom: 1px solid #e0e0e0;
}

.login-btn, .user-btn {
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
  text-transform: none !important;
  transition: all 0.3s ease !important;
}

.login-btn:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.user-btn:hover {
  opacity: 0.9 !important;
}

.v-badge__badge {
  font-size: 10px !important;
  height: 18px !important;
  min-width: 18px !important;
  padding: 0 4px !important;
}

.v-footer {
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1) !important;
}

/* 添加平滑滚动效果 */
html {
  scroll-behavior: smooth;
}

/* 为主内容区域添加过渡效果 */
.v-main {
  transition: opacity 0.3s ease;
}

/* 为登录对话框添加动画效果 */
.v-dialog-transition-enter-active, .v-dialog-transition-leave-active {
  transition: opacity 0.3s cubic-bezier(.25,.8,.25,1);
}

.v-dialog-transition-enter, .v-dialog-transition-leave-to {
  opacity: 0;
}

/* 移动端导航抽屉样式 */
.mobile-drawer .v-list-item {
  min-height: 48px !important;
  padding: 0 16px !important;
}

.mobile-drawer .v-list-item__icon {
  margin-right: 16px !important;
}

.mobile-drawer .v-list-item__title {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.mobile-drawer .v-list-group__header {
  padding-left: 16px !important;
}

.mobile-drawer .v-list-group__items .v-list-item {
  padding-left: 32px !important;
}

.mobile-drawer .v-list-item:hover,
.mobile-drawer .v-list-item--active {
  background-color: rgba(30, 73, 110, 0.1) !important;
}

.mobile-drawer .v-list-item:hover .v-list-item__title,
.mobile-drawer .v-list-item--active .v-list-item__title {
  color: #1e496e !important;
}
</style>